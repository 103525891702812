import { ofrDocumentTemplateService } from '.'
import {
  workspaceTypes,
  riskReliefSmartFunctionTypes,
} from '../constants/constants'
import {
  AlgorithmRun,
  BiomarkConfigValues,
  DelConfigValues,
  RiskReliefAlgorithmRun,
  RiskReliefConfig,
  QualityReviewConfigValues,
  RegulatorySubmissionConfigValues,
  WorkspaceConfig,
  WorkspaceDetails,
} from '../store/reducers/workspaceReducer'
// Service functions



const getBiomarkConfig = (
  algorithmRun: AlgorithmRun,
  selectedWorkspace: WorkspaceDetails
): BiomarkConfigValues | undefined => {
  return algorithmRun.config as BiomarkConfigValues
}

const getRegsubConfig = (
  algorithmRun: AlgorithmRun
): RegulatorySubmissionConfigValues | undefined => {
  return algorithmRun.config as RegulatorySubmissionConfigValues
}

const getDelConfig = (
  algorithmRun: AlgorithmRun
): DelConfigValues | undefined => {
  return algorithmRun.config as DelConfigValues
}
const getRiskRelConfig = async (
  searchQueryId?: string,
  selectedWorkspace?: WorkspaceDetails,
  algorithmRun?: RiskReliefAlgorithmRun
): Promise<RiskReliefConfig | undefined> => {
  if (!searchQueryId || !selectedWorkspace) return undefined

  const workspaceConfig = selectedWorkspace.config as WorkspaceConfig
  const deviations = workspaceConfig?.riskReliefConfig?.deviations
  if (!deviations) return undefined

  const baseConfig = {
    deviations: deviations
      .filter((deviation) => deviation.searchQueryId === searchQueryId)
      .map((deviation) => ({
        id: deviation.searchResultId,
        urn: deviation?.urn || '',
      })),
  } as RiskReliefConfig

  if (
    algorithmRun?.smartFunctionType ===
    riskReliefSmartFunctionTypes.CAPA_GENERATION
  ) {
    return {
      ...baseConfig,
      // needed for CAPA
      rootCauseExclusions:
        workspaceConfig?.riskReliefConfig?.rootCauseExclusions || {},
    }
  }

  return baseConfig
}

const getOfrQrConfig = async (
  selectedWorkspace: WorkspaceDetails
): Promise<QualityReviewConfigValues | undefined> => {
  const documentTemplateId = selectedWorkspace?.config?.ofrDocumentTemplatesId
  if (!documentTemplateId) return undefined

  try {
    const response = await ofrDocumentTemplateService.getOfrDocumentTemplate({
      documentId: documentTemplateId,
    })

    if (!response) return undefined

    return {
      documentTemplateId: response.documentId,
      documentTemplateFormPages: response.formPages,
      documentTemplateName:
        response.title || selectedWorkspace?.config?.ofrDocumentTemplatesName,
    } as QualityReviewConfigValues
  } catch (error) {
    console.error('Error fetching document template:', error)
    return undefined
  }
}

const getBaseAlgorithmConfig = (
  algorithmRun: AlgorithmRun,
  selectedWorkspace: WorkspaceDetails
) => {
  if (!algorithmRun || !selectedWorkspace) return undefined

  switch (selectedWorkspace.typeCode) {
    case workspaceTypes.BIOMARK:
      return getBiomarkConfig(algorithmRun, selectedWorkspace)
    case workspaceTypes.REGSUB:
      return getRegsubConfig(algorithmRun)
    case workspaceTypes.DEL:
      return getDelConfig(algorithmRun)
    default:
      return {}
  }
}

const getAlgorithmConfig = async (
  typeCode: string,
  workspace: WorkspaceDetails,
  searchQueryId?: string,
  algorithmRun?: RiskReliefAlgorithmRun | AlgorithmRun
) => {
  switch (typeCode) {
    case workspaceTypes.RISK_REL:
      if (!searchQueryId) {
        console.error('Missing deviationId for RISK_REL workspace')
        return {}
      }
      return getRiskRelConfig(
        searchQueryId,
        workspace,
        algorithmRun as RiskReliefAlgorithmRun
      )

    case workspaceTypes.OFR_QR: {
      const config = await getOfrQrConfig(workspace)
      if (!config) {
        return undefined
      }
      return config
    }

    case workspaceTypes.REFAI:
      return {}

    default:
      if (!algorithmRun) return {}
      return getBaseAlgorithmConfig(algorithmRun, workspace)
  }
}

const smartFunctionActionService = {
  getBiomarkConfig,
  getRegsubConfig,
  getDelConfig,
  getRiskRelConfig,
  getOfrQrConfig,
  getAlgorithmConfig,
}

export default smartFunctionActionService
