import { FieldTimeOutlined, UserOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  Badge,
  IconTextContainer,
  MultiColumnFormColumnContainer,
  MultiColumnFormContentContainer,
  SectionLoadingState,
} from '../../../components'
import {
  BadgeColors,
  NO_DATA_PLACEHOLDER,
  dateFormat,
  riskReliefDeviationStatuses,
  workspaceTypes,
} from '../../../constants/constants'
import { useSearchReviews } from '../../../hooks'
import useWorkspaceDetails from '../../../hooks/useWorkspaceDetails'
import { SearchQuery } from '../../../store/reducers/searchReviewReducer'

const SectionLoadingStateContainer = styled.div`
  display: flex;
  height: calc(100vh - 124px);
`

interface DeviationDetails {
  historicalReview?: string
  searchQuery?: string
  deviationStatus?: string
  deviationOwner?: string
  deviationLastUpdate?: string
  deviationDataSource?: string
}

const WorkspaceOverviewInfoDeviationPanel = () => {
  const { selectedWorkspace } = useWorkspaceDetails({ preventFetch: true })

  const { deviationId } = useParams()

  const {
    refreshSearchReviewList,
    refreshSearchReviewListDone,
    searchReviewList,
    loading,
  } = useSearchReviews({
    preventFetch: true,
  })

  const [statisticsContent, setStatisticsContent] =
    useState<JSX.Element | null>(null)

  useEffect(() => {
    const searchReviewIds =
      selectedWorkspace?.config?.riskReliefConfig?.searchReviewIds
    if (!!searchReviewIds?.length && !refreshSearchReviewListDone) {
      refreshSearchReviewList(searchReviewIds)
    }
  }, [selectedWorkspace, refreshSearchReviewList, refreshSearchReviewListDone])

  const deviationDetails: DeviationDetails | undefined = useMemo(() => {
    const review = searchReviewList ? searchReviewList[0] : undefined

    if (review) {
      const searchQuery = review.searchQueries?.find(
        (query: SearchQuery) => query.id === deviationId
      )

      if (searchQuery) {
        return {
          historicalReview: review.title,
          searchQuery: searchQuery.searchText,
          deviationStatus:
            selectedWorkspace?.config?.riskReliefConfig?.queryDeviations.find(
              (config) => config.searchQueryId === deviationId
            )?.deviationStatus || '',
          deviationOwner: searchQuery.searchActionBy.displayName,
          deviationLastUpdate: searchQuery.searchDate,
          deviationDataSource: searchQuery.searchSource.title,
        }
      }
    }
  }, [searchReviewList, selectedWorkspace, deviationId])

  useMemo(() => {
    let statistic = null
    switch (selectedWorkspace?.typeCode) {
      default:
        // TODO implement stats for specific smart func
        statistic = null
    }
    setStatisticsContent(statistic)
  }, [selectedWorkspace])

  const canShowStatistics = useMemo(() => {
    return selectedWorkspace?.typeCode === workspaceTypes.RISK_REL
  }, [selectedWorkspace])

  if (loading) {
    return (
      <SectionLoadingStateContainer>
        <SectionLoadingState />
      </SectionLoadingStateContainer>
    )
  }

  return (
    <Form layout="vertical" requiredMark={false}>
      <MultiColumnFormContentContainer>
        <MultiColumnFormColumnContainer>
          <Form.Item label={t('Search Query')}>
            {deviationDetails?.searchQuery || NO_DATA_PLACEHOLDER}
          </Form.Item>
          <Form.Item label={t('Historical Review')}>
            {deviationDetails?.historicalReview || NO_DATA_PLACEHOLDER}
          </Form.Item>
          <Form.Item label={t('Data Source')}>
            <Badge color={BadgeColors.DATA_SOURCE}>
              {deviationDetails?.deviationDataSource || NO_DATA_PLACEHOLDER}
            </Badge>
          </Form.Item>
          <Form.Item label={t('Status')}>
            <Badge
              color={
                deviationDetails?.deviationStatus ===
                riskReliefDeviationStatuses.OPENED
                  ? BadgeColors.STATUS_OPENED
                  : BadgeColors.STATUS_CLOSED
              }
            >
              {t(
                `workspaceDetailsPage.deviationStatus.${deviationDetails?.deviationStatus}`
              )}
            </Badge>
          </Form.Item>

          <Form.Item label={t('Last Update')}>
            <IconTextContainer>
              <FieldTimeOutlined />
              {deviationDetails?.deviationLastUpdate ? (
                <Moment
                  date={deviationDetails?.deviationLastUpdate}
                  format={dateFormat.PRIMARY}
                />
              ) : (
                NO_DATA_PLACEHOLDER
              )}
            </IconTextContainer>
          </Form.Item>
          <Form.Item label={t('Owner')}>
            <IconTextContainer>
              <UserOutlined />
              {deviationDetails?.deviationOwner || NO_DATA_PLACEHOLDER}
            </IconTextContainer>
          </Form.Item>
        </MultiColumnFormColumnContainer>
        {canShowStatistics && (
          <MultiColumnFormColumnContainer>
            <Form.Item
              label={t(
                'workspaceDetailsPage.workspaceOverviewInfoPanel.secondColumnTitle'
              )}
            >
              {selectedWorkspace?.algorithmRuns === undefined ||
              selectedWorkspace?.algorithmRuns?.length === 0 ? (
                <Paragraph
                  style={{
                    paddingLeft: '8px',
                    fontWeight: 400,
                    color: '#434343',
                  }}
                >
                  {t(
                    'workspaceDetailsPage.workspaceOverviewInfoPanel.secondColumnDescription'
                  )}
                </Paragraph>
              ) : (
                statisticsContent
              )}
            </Form.Item>
          </MultiColumnFormColumnContainer>
        )}
      </MultiColumnFormContentContainer>
    </Form>
  )
}

export default WorkspaceOverviewInfoDeviationPanel
