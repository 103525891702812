import {
  ApartmentOutlined,
  CloudDownloadOutlined,
  DatabaseOutlined,
  InfoCircleOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import { Radio } from 'antd'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IconTextContainer } from '../../../components'
import { routePaths } from '../../RootPage'
import {
  WorkspaceTab,
  workSpaceTabs,
  workspacePanelTypes,
} from '../WorkspaceDetailsPage'

interface WorkspaceTabsProps {
  singleWorkspace?: boolean
}

const WorkspaceTabs = ({ singleWorkspace }: WorkspaceTabsProps) => {
  const { panelType, tab, workspaceId, deviationId } = useParams()
  const navigate = useNavigate()
  const [selectedValue, setSelectedValue] = useState<WorkspaceTab>(
    (tab || workSpaceTabs.INFO) as WorkspaceTab
  )

  useEffect(() => {
    setSelectedValue((tab || workSpaceTabs.INFO) as WorkspaceTab)
  }, [tab])

  const handleChange = (value: WorkspaceTab) => {
    setSelectedValue(value)
    const baseRoute = singleWorkspace
      ? routePaths.SINGLE_WORKSPACE_DETAILS
      : routePaths.WORKSPACE_DETAILS
    const deviationIdString = deviationId ? `\\${deviationId}` : ''
    navigate(
      `${baseRoute}/${workspaceId}/${
        panelType || workspacePanelTypes.OVERVIEW
      }/${value || workSpaceTabs.INFO}${deviationIdString}`
    )
  }

  return (
    <Radio.Group
      value={selectedValue}
      defaultValue={workSpaceTabs.INFO}
      buttonStyle="solid"
    >
      <Radio.Button
        value={workSpaceTabs.INFO}
        onClick={() => handleChange(workSpaceTabs.INFO as WorkspaceTab)}
      >
        <IconTextContainer>
          <InfoCircleOutlined />
          {t('workspaceDetailsPage.workspaceTabs.tabInfo')}
        </IconTextContainer>
      </Radio.Button>
      {(!panelType || panelType === workspacePanelTypes.OVERVIEW) && (
        <Radio.Button
          value={workSpaceTabs.SMART_FUNCTION}
          onClick={() =>
            handleChange(workSpaceTabs.SMART_FUNCTION as WorkspaceTab)
          }
        >
          <IconTextContainer>
            <ApartmentOutlined />
            {t('workspaceDetailsPage.workspaceTabs.tabIntelligentSolution')}
          </IconTextContainer>
        </Radio.Button>
      )}
      {panelType === workspacePanelTypes.SETTINGS && (
        <>
          <Radio.Button
            value={workSpaceTabs.SOURCE}
            onClick={() => handleChange(workSpaceTabs.SOURCE as WorkspaceTab)}
          >
            <IconTextContainer>
              <DatabaseOutlined />
              {t('workspaceDetailsPage.workspaceTabs.tabSource')}
            </IconTextContainer>
          </Radio.Button>
          <Radio.Button
            value={workSpaceTabs.STORE}
            onClick={() => handleChange(workSpaceTabs.STORE as WorkspaceTab)}
          >
            <IconTextContainer>
              <CloudDownloadOutlined />
              {t('workspaceDetailsPage.workspaceTabs.tabStore')}
            </IconTextContainer>
          </Radio.Button>
          <Radio.Button
            value={workSpaceTabs.PEOPLE}
            onClick={() => handleChange(workSpaceTabs.PEOPLE as WorkspaceTab)}
          >
            <IconTextContainer>
              <TeamOutlined />
              {t('workspaceDetailsPage.workspaceTabs.tabPeople')}
            </IconTextContainer>
          </Radio.Button>
        </>
      )}
    </Radio.Group>
  )
}

export default WorkspaceTabs
