import { DetailsPageLayout } from '../../../components'
import RiskReliefCAPAGenerationSmartFunctionDetailsPageHeader from './components/RiskReliefCAPAGenerationSmartFunctionDetailsPageHeader'
import RiskReliefCAPAGenerationSmartFunctionDetailsPagePanel from './panels/RiskReliefCAPAGenerationSmartFunctionDetailsPagePanel'

const RiskReliefCAPAGenerationSmartFunctionDetailsPage = () => {
  return (
    <DetailsPageLayout>
      <RiskReliefCAPAGenerationSmartFunctionDetailsPageHeader />
      <RiskReliefCAPAGenerationSmartFunctionDetailsPagePanel />
    </DetailsPageLayout>
  )
}

export default RiskReliefCAPAGenerationSmartFunctionDetailsPage
