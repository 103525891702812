import { t } from 'i18next'
import { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { routePaths } from '../../../RootPage'
import RiskReliefSmartFunctionHeader from '../../components/RiskReliefSmartFunctionHeader'

const RiskReliefCAPAGenerationSmartFunctionDetailsPageHeader = () => {
  const { workspaceId, deviationId } = useParams()
  const navigate = useNavigate()
  const backButtonUrl = useMemo(
    () =>
      `${routePaths.WORKSPACE_DETAILS}/${workspaceId}/deviations/smartFunction/${deviationId}`,
    [workspaceId, deviationId]
  )

  const onRunChange = useCallback(
    (algorithmRunId?: string) => {
      const url = `${routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_CAPA_GENERATION}/${workspaceId}/${algorithmRunId}/${deviationId}`
      navigate(url)
    },
    [navigate, workspaceId, deviationId]
  )

  return (
    <RiskReliefSmartFunctionHeader
      title={t('workspaceDetailsPage.riskReliefSmartFunction.capaGeneration')}
      backButtonUrl={backButtonUrl}
      onRunChange={onRunChange}
    />
  )
}

export default RiskReliefCAPAGenerationSmartFunctionDetailsPageHeader
