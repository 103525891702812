import {
  AimOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
  BulbOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  CopyOutlined,
  HistoryOutlined,
  InboxOutlined,
  LinkOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { Button, Form, Tooltip } from 'antd'
import { t } from 'i18next'
import { useMemo } from 'react'
import Moment from 'react-moment'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  BadgeColors,
  NO_DATA_PLACEHOLDER,
  dateFormat,
  searchReviewSearchQueryResultItemStatus,
  searchReviewSearchQueryResultItemStatusColorKeys,
} from '../constants/constants'
import { useDownloadFile } from '../hooks'
import { routePaths } from '../pages/RootPage'
import notificationService from '../services/notificationService'
import InclusionSwitch from './InclusionSwitch'
import PageContentDropdown from './PageContentDropdown'
import Badge from './display/Badge'
import DetailsPageLayout from './display/DetailsPageLayout'
import IconTextContainer from './display/IconTextContainer'
import MultiColumnFormColumnContainer from './display/MultiColumnFormColumnContainer'
import MultiColumnFormContentContainer from './display/MultiColumnFormContentContainer'
import Spacer from './display/Spacer'
const SectionHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 8px;
`

const CopyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`

const FormItemText = styled.div`
  margin-left: 8px;
`

export interface DocumentDetailsFormatted {
  _id?: string
  authors?: string[]
  dataSource?: string
  language?: string
  originalPublicationDate?: string
  publicationName?: string
  title?: string
  url?: string
  csv?: string
  summary?: string
  summaryStatus?: string
}

interface DocumentDetailsLayoutProps {
  handleNextDocumentClick: () => void
  handlePreviousDocumentClick: () => void
  getDocumentIndex: () => string | number
  length: number | undefined
  document: DocumentDetailsFormatted
  includedSwitchValue?: boolean
  onSwitchClick?: (id: string) => void
  switchLoading?: boolean
  backUrl: string
  onSummarize?: () => void
  rightColumn?: React.ReactNode
}

const DocumentDetailsLayout = ({
  handleNextDocumentClick,
  handlePreviousDocumentClick,
  getDocumentIndex,
  length,
  document,
  includedSwitchValue,
  onSwitchClick,
  onSummarize,
  switchLoading,
  backUrl,
  rightColumn,
}: DocumentDetailsLayoutProps) => {
  const navigate = useNavigate()
  const downloadCsv = useDownloadFile()
  const location = useLocation()

  const isLiteratureReview = useMemo(
    () => location.pathname.includes(routePaths.SEARCH_REVIEW_DOCUMENT),
    [location.pathname]
  )

  const summaryExists = useMemo(() => !!document.summary, [document.summary])

  const formattedIndexText = useMemo(() => {
    const indexText = getDocumentIndex()
    return typeof indexText === 'string'
      ? indexText
      : indexText.toString().padStart(2, '0')
  }, [getDocumentIndex])

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(document.url || '')
    notificationService.notificationSuccess(
      t('documentDetailsLayout.urlToClipboard')
    )
  }

  const copySummaryToClipboard = () => {
    navigator.clipboard.writeText(document.summary || '')
    notificationService.notificationSuccess(
      t('documentDetailsLayout.summaryToClipboard')
    )
  }

  const closeDetails = () => {
    navigate(backUrl)
  }

  const handleExportClick = () => {
    const filename = `${document.title || 'document'}_export.csv`
    if (document?.csv) downloadCsv(document?.csv, filename)
  }

  const searchReviewSearchQueryResultItemStatusIcons = {
    [searchReviewSearchQueryResultItemStatus.PROCESSING]: <SyncOutlined spin />,
    [searchReviewSearchQueryResultItemStatus.READY]: <AimOutlined />,
    [searchReviewSearchQueryResultItemStatus.FAILED]: <CloseCircleOutlined />,
    [searchReviewSearchQueryResultItemStatus.INGESTION]: <SyncOutlined spin />,
  }

  const handleSummarize = () => {
    if (isLiteratureReview && onSummarize) {
      onSummarize()
    }
  }

  return (
    <DetailsPageLayout>
      <Form layout="vertical">
        <SectionHeaderContainer>
          <Tooltip
            title={t('documentDetailsLayout.backToSearchResultsTooltip')}
            placement="right"
          >
            <Button icon={<CloseOutlined />} onClick={closeDetails} />
          </Tooltip>
          <Tooltip
            title={t('documentDetailsLayout.previousDocumentTooltip')}
            placement="right"
          >
            <Button
              type="default"
              icon={<ArrowUpOutlined />}
              onClick={handlePreviousDocumentClick}
            />
          </Tooltip>
          <Tooltip
            title={t('documentDetailsLayout.nextDocumentTooltip')}
            placement="right"
          >
            <Button
              icon={<ArrowDownOutlined />}
              onClick={handleNextDocumentClick}
            />
          </Tooltip>
          <InboxOutlined />
          <div>
            {t('documentDetailsLayout.title', {
              index: formattedIndexText,
              total: length,
            })}
          </div>
          {onSwitchClick && (
            <InclusionSwitch
              checked={includedSwitchValue}
              onClick={() => onSwitchClick && onSwitchClick(document._id || '')}
              loading={switchLoading}
            />
          )}

          {document?.summaryStatus && isLiteratureReview && (
            <Badge
              color={
                searchReviewSearchQueryResultItemStatusColorKeys[
                  document.summaryStatus
                ] || BadgeColors.DEFAULT
              }
              icon={
                searchReviewSearchQueryResultItemStatusIcons[
                  document.summaryStatus
                ]
              }
            >
              {
                searchReviewSearchQueryResultItemStatusColorKeys[
                  document.summaryStatus
                ]
              }
            </Badge>
          )}

          <Spacer />

          <Button
            type="text"
            htmlType="submit"
            href={document?.url || ''}
            target="_blank"
            icon={<LinkOutlined />}
          >
            {t('documentDetailsLayout.launchLink')}
          </Button>
          {isLiteratureReview && (
            <Button
              htmlType="submit"
              onClick={handleSummarize}
              target="_blank"
              icon={<BulbOutlined />}
            >
              {t('documentDetailsLayout.summarize')}
            </Button>
          )}
          <PageContentDropdown onExport={handleExportClick} />
        </SectionHeaderContainer>
        <MultiColumnFormContentContainer>
          <MultiColumnFormColumnContainer>
            <Form.Item label={t('Title')}>
              <FormItemText>
                {document?.title || NO_DATA_PLACEHOLDER}
              </FormItemText>
            </Form.Item>
            <Form.Item label={t('Provenance')}>
              <FormItemText>
                {document?.publicationName || NO_DATA_PLACEHOLDER}
              </FormItemText>
            </Form.Item>
            <Form.Item label={t('Authors')}>
              <FormItemText>
                {document?.authors?.join(', ') || NO_DATA_PLACEHOLDER}
              </FormItemText>
            </Form.Item>
            <Form.Item
              label={t('documentDetailsLayout.originalPublicationDate')}
            >
              <FormItemText>
                <IconTextContainer>
                  <HistoryOutlined />
                  <Moment
                    date={document?.originalPublicationDate}
                    format={dateFormat.PRIMARY}
                  />
                </IconTextContainer>
              </FormItemText>
            </Form.Item>
            <Form.Item label={t('Language')}>
              <FormItemText>
                {document?.language
                  ? t(`${document.language}`)
                  : NO_DATA_PLACEHOLDER}
              </FormItemText>
            </Form.Item>
            <CopyContainer>
              <Form.Item
                label={t('Link')}
                style={{
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <FormItemText>
                  {document?.url ? document.url : NO_DATA_PLACEHOLDER}
                </FormItemText>
              </Form.Item>

              <Button
                onClick={copyLinkToClipboard}
                type="text"
                icon={<CopyOutlined />}
              />
            </CopyContainer>
          </MultiColumnFormColumnContainer>
          <MultiColumnFormColumnContainer>
            {rightColumn
              ? rightColumn
              : summaryExists && (
                  <CopyContainer>
                    <Form.Item label={t('Summary')}>
                      <FormItemText>
                        {document?.summary || NO_DATA_PLACEHOLDER}
                      </FormItemText>
                    </Form.Item>

                    <Button
                      onClick={copySummaryToClipboard}
                      type="text"
                      icon={<CopyOutlined />}
                    />
                  </CopyContainer>
                )}
          </MultiColumnFormColumnContainer>
        </MultiColumnFormContentContainer>
      </Form>
    </DetailsPageLayout>
  )
}

export default DocumentDetailsLayout
