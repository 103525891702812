import { DatabaseOutlined, FilterOutlined } from '@ant-design/icons'
import Table, { ColumnsType } from 'antd/lib/table'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { MouseEvent, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  BadgeColors,
  NO_DATA_PLACEHOLDER,
  dateFormat,
  urlList,
} from '../constants/constants'
import { useDataSources } from '../hooks'
import { routePaths } from '../pages/RootPage'
import { apiService } from '../services'
import {
  SearchQuery,
  setSelectedSearchQuery,
} from '../store/reducers/searchReviewReducer'
import ConfirmDeleteModal from './ConfirmDeleteModal'
import DeleteIconButton from './DeleteIconButton'
import Badge from './display/Badge'
import TableBoldColumnContainer from './display/TableBoldColumnContainer'
const BadgesContainer = styled.div`
  display: flex;
  gap: 8px;
`

const NoWrapContainer = styled.div`
  white-space: nowrap;
`

interface ExtendedSearchQuery extends SearchQuery {
  key: string
}

interface SearchQueryListTableProps {
  searchQueries?: SearchQuery[]
  searchReviewId?: string
  useDelete?: boolean
  onDelete: () => void
}

const SearchQueryListTable = ({
  searchQueries,
  searchReviewId,
  useDelete,
  onDelete,
}: SearchQueryListTableProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [SearchQueryToDelete, setSearchQueryToDelete] = useState('')
  const { getDataSourceTypeText } = useDataSources({
    preventFetch: true,
  })

  const formattedData: ExtendedSearchQuery[] =
    useMemo(
      () =>
        searchQueries?.map((query: SearchQuery) => ({
          ...query,
          key: query.searchEvent,
        })),
      [searchQueries]
    ) || []

  const handleRowClick = (searchEvent: string) => {
    const searchQuery =
      searchQueries &&
      searchQueries.find((query) => query.searchEvent === searchEvent)

    if (searchQuery) {
      dispatch(setSelectedSearchQuery(searchQuery))
      navigate(
        `${routePaths.SEARCH_REVIEW_SEARCH}/${searchReviewId}/${searchQuery.searchEvent}`
      )
    }
  }

  const handleCancel = () => {
    setModalOpen(false)
  }

  const handleConfirm = () => {
    setModalOpen(false)
    setLoading(true)

    apiService
      .deleteItem(
        `${urlList.SEARCH_REVIEWS}/${searchReviewId}/searchId=${SearchQueryToDelete}`,
        navigate
      )
      .then((response) => {
        if (onDelete) {
          onDelete()
        }
        setLoading(false)
      })
      .catch((error: AxiosError | Error) => {
        setLoading(false)
        console.error('axios delete error', error)
      })
  }

  const handleDelete = (event: MouseEvent, searchEvent: string) => {
    event.stopPropagation()
    setModalOpen(true)
    setSearchQueryToDelete(searchEvent)
  }

  const columns: ColumnsType<ExtendedSearchQuery> = [
    {
      title: t('searchQueryListTable.searchQuery'),
      dataIndex: 'searchText',
      key: 'searchText',
      render: (text) => (
        <TableBoldColumnContainer>
          {text || NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
      width: '100%',
    },
    {
      title: t('searchQueryListTable.searchDate'),
      dataIndex: 'searchDate',
      key: 'searchDate',
      render: (textDate) => (
        <NoWrapContainer>
          {textDate ? (
            <Moment local format={dateFormat.PRIMARY}>
              {textDate}
            </Moment>
          ) : (
            NO_DATA_PLACEHOLDER
          )}
        </NoWrapContainer>
      ),
    },
    {
      title: t('searchQueryListTable.filters'),
      dataIndex: 'filters',
      key: 'filters',
      render: (_, record) => {
        const sourceText = getDataSourceTypeText(record.searchSource)
        return (
          <>
            <BadgesContainer>
              {sourceText && (
                <Badge
                  color={BadgeColors.DATA_SOURCE}
                  icon={<DatabaseOutlined />}
                >
                  {sourceText}
                </Badge>
              )}
              {record.searchParameters.date && (
                <Badge color={BadgeColors.FILTERS} icon={<FilterOutlined />}>
                  {record.searchParameters.date}
                </Badge>
              )}
            </BadgesContainer>
          </>
        )
      },
    },
    {
      title: t('searchQueryListTable.results'),
      dataIndex: 'results',
      key: 'results',
      render: (_, record) => (
        <NoWrapContainer>
          {t('Result total')}: {record.searchResults.length}
        </NoWrapContainer>
      ),
    },
    {
      key: 'action',
      dataIndex: 'action',
      render: (_, record) => (
        <>
          {useDelete && (
            <DeleteIconButton
              tooltipTitle={t('searchQueryListTable.deleteSearchQueryTooltip')}
              onClick={(e: MouseEvent) => handleDelete(e, record.searchEvent)}
            />
          )}
        </>
      ),
      width: 40,
    },
  ]

  return (
    <>
      <Table
        rowClassName="page-list-table-row clickable"
        columns={columns}
        dataSource={formattedData}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              handleRowClick && handleRowClick(record.searchEvent)
            }, // click row
          }
        }}
      />
      <ConfirmDeleteModal
        open={modalOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        loading={loading}
      >
        {t('searchQueryListItem.confirmDeleteModalContent')}
      </ConfirmDeleteModal>
    </>
  )
}

export default SearchQueryListTable
