import { CopyOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { TooltipPlacement } from 'antd/lib/tooltip'
import { t } from 'i18next'
import React from 'react'
import notificationService from '../services/notificationService'

interface CopyButtonProps {
  text: string
  tooltipText?: string
  successMessage?: string
  placement?: TooltipPlacement
}

const CopyButton = ({
  text,
  tooltipText,
  successMessage,
  placement,
}: CopyButtonProps) => {
  const handleCopy = (e: React.MouseEvent) => {
    e.stopPropagation()
    navigator.clipboard.writeText(text)
    notificationService.notificationSuccess(
      successMessage || t('copyButton.copySuccess')
    )
  }
  return (
    <Tooltip
      title={tooltipText || t('copyButton.copyTooltip')}
      placement={placement}
    >
      <Button icon={<CopyOutlined />} onClick={handleCopy} type="text" />
    </Tooltip>
  )
}

export default CopyButton
