import { ThemeConfig } from 'antd'

const baseTheme: ThemeConfig = {
  token: {
    fontFamily: 'Inter',
    fontSize: 14,
    lineHeight: 1.5,
  },
}

export type ThemeType = 'light' | 'dark'

const themeColorKeys: Record<ThemeType, ThemeType> = {
  light: 'light',
  dark: 'dark',
}

const lightTheme: ThemeConfig = {
  ...baseTheme,
  token: {
    ...baseTheme.token,
    colorBgSpotlight: '#000000',
    //Backgrounds
    colorBgBase: '#FFFFFF',
    colorBgLayout: '#F5F5F5',
    colorBgContainer: '#FFFFFF',
    colorBgContainerDisabled: '#0000000A',
    colorBgElevated: '#FFFFFF',
    colorBgMask: '#00000005',
    colorFillAlter: 'rgba(0, 0, 0, 0.02)',

    //Texts
    colorText: '#000000',
    colorTextBase: '#000000',
    colorTextLightSolid: '#FFFFFF',
    colorTextSecondary: '#00000073',

    //Borders & Dividers
    colorBorder: 'rgba(0, 0, 0, 0.15)',
    colorBorderSecondary: 'rgba(0, 0, 0, 0.06)',
    colorSplit: 'rgba(0, 0, 0, 0.06)',

    //Primary
    colorPrimary: '#7A40EA',
    colorPrimaryBg: '#E8E3FC',
    colorPrimaryBgHover: '#D2C7F9',
    colorPrimaryBorder: '#B9A7F6',
    colorPrimaryBorderHover: '#A185F3',
    colorPrimaryHover: '#8C63EF',
    colorPrimaryActive: '#6726D3',
    colorPrimaryTextHover: '#521DAA',
    colorPrimaryText: '#3E1483',
    colorPrimaryTextActive: '#280A59',

    //Success
    colorSuccess: '#52C41A',
    colorSuccessBg: '#F6FFED',
    colorSuccessBgHover: '#D9F7BE',
    colorSuccessBorder: '#B7EB8F',
    colorSuccessBorderHover: '#95DE64',
    colorSuccessHover: '#73D13D',
    colorSuccessActive: '#389E0D',
    colorSuccessTextHover: '#237804',
    colorSuccessText: '#135200',
    colorSuccessTextActive: '#092B00',

    //Info
    colorInfo: '#1677FF',
    colorInfoBg: '#E6F4FF',
    colorInfoBgHover: '#BAE0FF',
    colorInfoBorder: '#91CAFF',
    colorInfoBorderHover: '#69B1FF',
    colorInfoHover: '#4096FF',
    colorInfoActive: '#0958D9',
    colorInfoTextHover: '#003EB3',
    colorInfoText: '#002C8C',
    colorInfoTextActive: '#001D66',

    //Warning
    colorWarning: '#FAAD14',
    colorWarningBg: '#FFFBE6',
    colorWarningBgHover: '#FFF1B8',
    colorWarningBorder: '#FFE58F',
    colorWarningBorderHover: '#FFD666',
    colorWarningHover: '#FFC53D',
    colorWarningActive: '#D48806',
    colorWarningTextHover: '#AD6800',
    colorWarningText: '#874D00',
    colorWarningTextActive: '#613400',

    //Error
    colorError: '#FF4D4F',
    colorErrorBg: '#FFF1F0',
    colorErrorBgHover: '#FFCCC7',
    colorErrorBorder: '#FFA39E',
    colorErrorBorderHover: '#FF7875',
    colorErrorHover: '#FF7875',
    colorErrorActive: '#CF1322',
    colorErrorTextHover: '#A8071A',
    colorErrorText: '#820014',
    colorErrorTextActive: '#5C0011',
  },
}

const darkTheme: ThemeConfig = {
  //Backgrounds
  ...baseTheme,
  token: {
    ...baseTheme.token,
    colorBgSpotlight: '#1F1F1F',
    colorBgBase: '#000000',
    colorBgLayout: '#000000',
    colorBgContainer: '#141414',
    colorBgContainerDisabled: '#FFFFFF14',
    colorBgElevated: '#1F1F1F',
    colorBgMask: '#FFFFFF0A',
    colorFillAlter: 'rgba(255, 255, 255, 0.04)',

    //Texts
    colorText: '#FFFFFFD9',
    colorTextBase: '#FFFFFF',
    colorTextLightSolid: '#FFFFFF',
    colorTextSecondary: '#FFFFFF73',

    //Borders & Dividers
    colorBorder: 'rgba(66, 66, 66, 1)',
    colorBorderSecondary: '#303030',
    colorSplit: 'rgba(255, 255, 255, 0.06)',

    //Primary
    colorPrimary: '#926DF0',
    colorPrimaryBg: '#270958',
    colorPrimaryBgHover: '#3D1381',
    colorPrimaryBorder: '#541DAE',
    colorPrimaryBorderHover: '#6D28DD',
    colorPrimaryHover: '#804CEC',
    colorPrimaryActive: '#A68BF4',
    colorPrimaryTextHover: '#BBA9F6',
    colorPrimaryText: '#D0C6F9',
    colorPrimaryTextActive: '#E8E3FC',

    //Success
    colorSuccess: '#49AA19',
    colorSuccessBg: '#162312',
    colorSuccessBgHover: '#1D3712',
    colorSuccessBorder: '#274916',
    colorSuccessBorderHover: '#306317',
    colorSuccessHover: '#6ABE39',
    colorSuccessActive: '#3C8618',
    colorSuccessTextHover: '#8FD460',
    colorSuccessText: '#B2E58B',
    colorSuccessTextActive: '#D5F2BB',

    //Info
    colorInfo: '#1668DC',
    colorInfoBg: '#111A2C',
    colorInfoBgHover: '#112545',
    colorInfoBorder: '#15325B',
    colorInfoBorderHover: '#15417E',
    colorInfoHover: '#3C89E8',
    colorInfoActive: '#1554AD',
    colorInfoTextHover: '#65A9F3',
    colorInfoText: '#8DC5F8',
    colorInfoTextActive: '#B7DCFA',

    //Warning
    colorWarning: '#D89614',
    colorWarningBg: '#2B2111',
    colorWarningBgHover: '#443111',
    colorWarningBorder: '#594214',
    colorWarningBorderHover: '#7C5914',
    colorWarningHover: '#E8B339',
    colorWarningActive: '#AA7714',
    colorWarningTextHover: '#F3CC62',
    colorWarningText: '#F8DF8B',
    colorWarningTextActive: '#FAEDB5',

    //Error
    colorError: '#E84749',
    colorErrorBg: '#2A1215',
    colorErrorBgHover: '#431418',
    colorErrorBorder: '#58181C',
    colorErrorBorderHover: '#791A1F',
    colorErrorHover: '#F37370',
    colorErrorActive: '#791A1F',
    colorErrorTextHover: '#F37370',
    colorErrorText: '#F89F9A',
    colorErrorTextActive: '#FAC8C3',
  },
}

interface ColorSet {
  background: string
  border: string
  text: string
}

interface BadgeColors {
  [theme: string]: { [colorKey1: string]: ColorSet }
}

export const badgeColors: BadgeColors = {
  light: {
    default: {
      background: '#FAFAFA',
      border: '#D9D9D9',
      text: '#000000',
    },
    error: {
      background: '#FFF1F0',
      border: '#FFA39E',
      text: '#820014',
    },
    success: {
      background: '#F6FFED',
      border: '#B7EB8F',
      text: '#135200',
    },
    processing: {
      background: '#E6F4FF',
      border: '#91CAFF',
      text: '#002C8C',
    },
    warning: {
      background: '#FFFBE6',
      border: '#FFE58F',
      text: '#874D00',
    },
    dataSource: {
      background: '#E6FFFB',
      border: '#87E8DE',
      text: '#006D75',
    },
    rootCauseDiscovery: {
      background: '#FFFBE6',
      border: '#FFE58F',
      text: '#874D00',
    },
    filters: {
      background: '#FFF0F6',
      border: '#FFADD2',
      text: '#C41D7F',
    },
    searchReviews: {
      background: '#F9F0FF',
      border: '#D3ADF7',
      text: '#531DAB',
    },
    templateAnnotations: {
      background: '#FFF2E8',
      border: '#FFB39A',
      text: '#873800',
    },
    statusOpened: {
      background: '#FCFFE6',
      border: '#EAFF8F',
      text: '#3F6600',
    },
    statusClosed: {
      background: '#00000005',
      border: '#00000026',
      text: '#000000E0',
    },
  },
  dark: {
    default: {
      background: '#141414',
      border: '#424242',
      text: '#FFFFFF',
    },
    error: {
      background: '#2A1215',
      border: '#58181C',
      text: '#F89F9A',
    },
    success: {
      background: '#162312',
      border: '#274916',
      text: '#B2E58B',
    },
    processing: {
      background: '#111A2C',
      border: '#15325B',
      text: '#8DC5F8',
    },
    warning: {
      background: '#2B2111',
      border: '#594214',
      text: '#F8DF8B',
    },
    dataSource: {
      background: '#112123',
      border: '#144848',
      text: '#87E8DE',
    },
    rootCauseDiscovery: {
      background: '#2B2111',
      border: '#594214',
      text: '#F8DF8B',
    },
    filters: {
      background: '#291321',
      border: '#551C3B',
      text: '#FFB6D9',
    },
    searchReviews: {
      background: '#120338',
      border: '#32075D',
      text: '#D3ADF7',
    },
    templateAnnotations: {
      background: '#2B1D11',
      border: '#592716',
      text: '#FFBB96',
    },
    statusOpened: {
      background: '#1F2611',
      border: '#3E4F13',
      text: '#E4F88B',
    },
    statusClosed: {
      background: '#141414',
      border: '#424242',
      text: '#FFFFFF',
    },
  },
}

export { darkTheme, lightTheme, themeColorKeys }
