import styled from 'styled-components'

export const SmartFunctionListPanelLayout = styled.div`
  background-color: ${({ theme }) => theme.token.colorBgLayout} !important;
  margin-left: -16px;
  margin-right: -16px;
  padding: 16px;
  border-radius: 16px 16px 0px 0px;
  @media print {
    height: auto;
  }
`
