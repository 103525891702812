import { TooltipPlacement } from 'antd/lib/tooltip'
import styled from 'styled-components'
import CopyButton from './CopyButton'

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`

const TextContent = styled.div`
  flex: 1;
  word-break: break-word;
`

interface TextWithCopyButtonProps {
  text: string
  tooltipText?: string
  successMessage?: string
  placement?: TooltipPlacement
}

const TextWithCopyButton = ({
  text,
  tooltipText,
  successMessage,
  placement,
}: TextWithCopyButtonProps) => {
  if (!text) return null

  return (
    <Container>
      <TextContent>{text}</TextContent>
      <CopyButton 
        text={text}
        tooltipText={tooltipText}
        successMessage={successMessage}
        placement={placement}
      />
    </Container>
  )
}

export default TextWithCopyButton 