import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { t } from 'i18next'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import {
  Badge,
  SectionLoadingState,
  TextWithCopyButton,
} from '../../../../components'
import {
  BadgeColors,
  NO_DATA_PLACEHOLDER,
} from '../../../../constants/constants'
import { useAlgorithmRunDetails } from '../../../../hooks'
import {
  CAPAGeneration,
  RiskReliefCapaGenerationRunResult,
} from '../../../../store/reducers/workspaceReducer'
import { SmartFunctionListPanelLayout } from '../../components/SmartFunctionListPanelLayout'

const SectionLoadingStateContainer = styled.div`
  display: flex;
  height: calc(100vh - 224px);
`

const StyledTable = styled(Table)`
  height: 100%;
  display: flex;
  flex-direction: column;
  .ant-table-cell {
    vertical-align: top !important;
    padding-top: 8px !important;
  }

  .ant-badge {
    margin-top: 0;
  }
`

const BadgeContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 0;
`

interface CAPAGenerationRow extends CAPAGeneration {
  key: string
}

const RiskReliefCAPAGenerationSmartFunctionDetailsPagePanel = () => {
  const { workspaceId, algorithmRunId } = useParams()
  const { loading, selectedAlgorithmRun } = useAlgorithmRunDetails({
    workspaceId,
    algorithmRunId,
    preventFetch: true,
  })

  const nodeResults = useMemo(
    () =>
      selectedAlgorithmRun?.nodeResults
        ? (selectedAlgorithmRun?.nodeResults
            ?.CAPA_GENERATION as RiskReliefCapaGenerationRunResult)
        : undefined,
    [selectedAlgorithmRun]
  )

  const formattedData: CAPAGenerationRow[] = useMemo(
    () =>
      nodeResults && nodeResults.capaGenerations
        ? nodeResults.capaGenerations.map(
            (capa: CAPAGeneration) =>
              ({
                ...capa,
                key: capa.id,
              } as CAPAGenerationRow)
          )
        : [],
    [nodeResults]
  )

  const columns: ColumnsType<CAPAGeneration> = [
    {
      title: t('smartFunctionDetailsPage.riskReliefCAPA.tableTitles.rootCause'),
      dataIndex: 'rootCause',
      key: 'rootCause',
      width: '15%',
      render: (text) => (
        <BadgeContainer>
          <Badge color={BadgeColors.ROOT_CAUSE_DISCOVERY}>
            {text ||
              t('smartFunctionDetailsPage.riskReliefRootCause.noRootCause')}
          </Badge>
        </BadgeContainer>
      ),
    },
    {
      title: t(
        'smartFunctionDetailsPage.riskReliefCAPA.tableTitles.correctiveAction'
      ),
      dataIndex: 'correctiveAction',
      key: 'correctiveAction',
      width: '43%',
      render: (text) =>
        text ? (
          <TextWithCopyButton text={text} placement="left" />
        ) : (
          NO_DATA_PLACEHOLDER
        ),
    },
    {
      title: t(
        'smartFunctionDetailsPage.riskReliefCAPA.tableTitles.preventiveAction'
      ),
      dataIndex: 'preventiveAction',
      key: 'preventiveAction',
      width: '42%',
      render: (text) =>
        text ? (
          <TextWithCopyButton text={text} placement="left" />
        ) : (
          NO_DATA_PLACEHOLDER
        ),
    },
  ]

  return loading ? (
    <SectionLoadingStateContainer>
      <SectionLoadingState />
    </SectionLoadingStateContainer>
  ) : (
    <SmartFunctionListPanelLayout>
      <StyledTable
        columns={columns}
        dataSource={formattedData}
        loading={loading}
        rowKey="id"
        pagination={false}
        scroll={{ y: 'calc(100vh - 202px)' }}
        rowClassName="page-list-table-row"
      />
    </SmartFunctionListPanelLayout>
  )
}

export default RiskReliefCAPAGenerationSmartFunctionDetailsPagePanel
