import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EllipsisOutlined,
  MinusCircleTwoTone,
  PauseOutlined,
  PlayCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import React from 'react'
import { smartFunctionBadgeStatusIconMaps } from '../../constants/constants'
import { AlgorithmRunStatus } from '../../types/generalTypes'

const iconMapping: Record<string, React.ElementType> = {
  SyncOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleTwoTone,
  PauseOutlined,
  PlayCircleOutlined,
  EllipsisOutlined,
}

interface SmartFunctionRunStatusIconProps {
  status: AlgorithmRunStatus
}

const SmartFunctionRunStatusIcon = ({
  status,
}: SmartFunctionRunStatusIconProps) => {
  const IconComponent = iconMapping[smartFunctionBadgeStatusIconMaps[status]]
  return IconComponent ? <IconComponent /> : null
}

export default SmartFunctionRunStatusIcon
