import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Table } from 'antd'
import { ColumnType } from 'antd/es/table'
import { styled } from 'styled-components'
import { MouseEvent } from 'react'

const ExpandIconButton = styled(Button)`
  border-radius: 4px;
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
`

interface ExpandableTableProps {
  columns: ColumnType<any>[]
  dataSource: any[]
  loading?: boolean
  scroll?: any
  handleRowClick?: (e: MouseEvent, record: any) => void
  expandedRowRender?: (record: any) => JSX.Element
  tableClass?: string
  rowClassName?: string
}

const ExpandableTable = ({
  expandedRowRender,
  handleRowClick,
  tableClass,
  rowClassName = 'page-list-table-row',
  ...props
}: ExpandableTableProps) => {
  return (
    <Table
      rowClassName={`${rowClassName} clickable`}
      className={tableClass}
      pagination={false}
      expandable={{
        expandedRowRender,
        rowExpandable: () => true,
        defaultExpandAllRows: false,
        expandRowByClick: false,
        showExpandColumn: true,
        expandIcon: ({ expanded, onExpand, record }) => (
          <ExpandIconButton
            type={expanded ? 'primary' : 'default'}
            size="small"
            icon={expanded ? <MinusOutlined /> : <PlusOutlined />}
            onClick={(e) => {
              e.stopPropagation()
              onExpand(record, e)
            }}
          />
        ),
      }}
      onRow={(record: any) => ({
        onClick: (e: MouseEvent) => {
          if (typeof handleRowClick !== 'undefined') {
            handleRowClick(e, record)
          }
        },
      })}
      {...props}
    />
  )
}

export default ExpandableTable
