import React from 'react'
import { DetailsPageLayout } from '../../../components'
import RiskReliefRootCauseDiscoverySmartFunctionDetailsPageHeader from './components/RiskReliefRootCauseDiscoverySmartFunctionDetailsPageHeader'
import RiskReliefRootCauseDiscoverySmartFunctionDetailsPagePanel from './panels/RiskReliefRootCauseDiscoverySmartFunctionDetailsPagePanel'

const RiskReliefRootCauseDiscoverySmartFunctionDetailsPage = () => {
  return (
    <DetailsPageLayout>
      <RiskReliefRootCauseDiscoverySmartFunctionDetailsPageHeader />
      <RiskReliefRootCauseDiscoverySmartFunctionDetailsPagePanel />
    </DetailsPageLayout>
  )
}

export default RiskReliefRootCauseDiscoverySmartFunctionDetailsPage
