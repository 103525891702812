import { Select, theme } from 'antd'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { riskReliefDeviationAllOwnersStatus } from '../../../constants/constants'
import { useSearchReviews, useUsers, useWorkspaceDetails } from '../../../hooks'
import {
  SearchQuery,
  SearchReview,
} from '../../../store/reducers/searchReviewReducer'
import { SelectOption } from '../../../types/generalTypes'
const { useToken } = theme

interface SelectDeviationOwnerProps {
  defaultValue?: string
  value?: string
  onChange?: (value: string | undefined) => void
  loading?: boolean
  fetchData?: boolean
}

const SelectDeviationOwner = ({
  defaultValue,
  value,
  onChange,
  loading,
  fetchData = false,
}: SelectDeviationOwnerProps) => {
  const { workspaceId } = useParams()
  const { token } = useToken()
  const { selectedWorkspace } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    defaultValue
  )
  const { findUser } = useUsers({})
  const {
    refreshSearchReviewList,
    refreshSearchReviewListDone,
    searchReviewList,
    loading: searchReviewLoading,
  } = useSearchReviews({
    preventFetch: true,
  })

  const [options, setOptions] = useState<SelectOption[]>([])

  useEffect(() => {
    const searchReviewIds =
      selectedWorkspace?.config?.riskReliefConfig?.searchReviewIds
    if (
      !!searchReviewIds?.length &&
      !refreshSearchReviewListDone &&
      fetchData &&
      !searchReviewLoading
    ) {
      refreshSearchReviewList(searchReviewIds)
    }
  }, [
    selectedWorkspace,
    refreshSearchReviewList,
    fetchData,
    refreshSearchReviewListDone,
    searchReviewLoading,
  ])

  useEffect(() => {
    const fetchOptions = async () => {
      if (
        !selectedWorkspace?.config?.riskReliefConfig?.searchReviewIds?.length
      ) {
        return
      }

      try {
        const uniqueOwners = new Set<string>()
        if (searchReviewList) {
          searchReviewList.forEach((review: SearchReview) => {
            if (review?.searchQueries?.length) {
              review.searchQueries.forEach((query: SearchQuery) => {
                if (query?.searchActionBy?.id) {
                  uniqueOwners.add(query.searchActionBy.id)
                }
              })
            }
          })
        }

        const ownerOptions = Array.from(uniqueOwners).map((ownerId) => {
          const user = findUser(ownerId)
          return {
            label: user?.displayName || ownerId,
            value: ownerId,
          }
        })

        setOptions(ownerOptions)
      } catch (error) {
        console.error('Error fetching owner options:', error)
      }
    }

    fetchOptions()
  }, [selectedWorkspace, refreshSearchReviewList, findUser, searchReviewList])

  useEffect(() => {
    if (value) {
      setSelectedValue(value)
    }
  }, [value])

  const handleChange = (value: string) => {
    setSelectedValue(value)
    if (onChange) {
      onChange(value)
    }
  }

  const allOptions = useMemo(
    () => [
      {
        label: t('workspaceDetailsPage.selectDeviationOwner.all'),
        value: riskReliefDeviationAllOwnersStatus,
      },
      ...options,
    ],
    [options]
  )

  return (
    <Select
      style={{ minWidth: 200, color: token.colorPrimary }}
      onChange={handleChange}
      value={selectedValue}
      options={allOptions}
      placeholder={t('workspaceDetailsPage.selectDeviationOwner.all')}
      loading={loading || searchReviewLoading}
    />
  )
}

export default SelectDeviationOwner
