import { Theme } from 'antd/lib/config-provider/context'
import styled from 'styled-components'

interface DetailsPageLayoutProps {
  bgColor?: string
  theme: Theme
}

const DetailsPageLayout = styled.div<DetailsPageLayoutProps>`
  background: ${({ theme, bgColor }) => bgColor || theme.token.colorBgLayout};
  padding: 16px;
  min-height: calc(100vh - 64px);
  border-radius: 16px 16px 0px 0px;
`

export default DetailsPageLayout
