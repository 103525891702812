import {
  SearchQuery,
  SearchReview,
} from '../store/reducers/searchReviewReducer'

import { Tooltip } from 'antd'
import { BreadcrumbItemType } from 'antd/lib/breadcrumb/Breadcrumb'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { routePaths } from '../pages/RootPage'
import { DataSource } from '../store/reducers/dataSourceReducer'
import { DataStore } from '../store/reducers/dataStoreReducer'
import { OfrDocumentTemplate } from '../store/reducers/ofrDocumentTemplateReducer'
import { User } from '../store/reducers/userReducer'
import { Workspace } from '../store/reducers/workspaceReducer'
import dataSourceService from './dataSourcesService'

interface MenuItem {
  key?: React.Key
  title?: React.ReactNode
  label?: React.ReactNode
  path?: string
  href?: string
}

const MAX_BREADCRUMB_TEXT_LENGTH = 40

const TruncatedText = styled.span`
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: bottom;
`

const StyledTooltip = styled(Tooltip)`
  display: inline-block;
  vertical-align: bottom;
`

const truncateWithTooltip = (text: string) => {
  if (text.length <= MAX_BREADCRUMB_TEXT_LENGTH) {
    return text
  }

  const truncatedText = `${text.substring(0, MAX_BREADCRUMB_TEXT_LENGTH)}...`

  return (
    <StyledTooltip title={text} placement="bottom" overlayStyle={{ pointerEvents: 'none' }}>
      <TruncatedText>{truncatedText}</TruncatedText>
    </StyledTooltip>
  )
}

const getWorkspaceMenuItems = (workspaceList: Workspace[]) => {
  if (!workspaceList) return []

  const menuItems: MenuItem[] = workspaceList.map((workspace) => ({
    key: workspace.id,
    label: (
      <Link to={`${routePaths.WORKSPACE_DETAILS}/${workspace.id}`}>
        {truncateWithTooltip(workspace.title)}
      </Link>
    ),
  }))
  return menuItems
}

const getSearchReviewMenuItems = (searchReviewList?: SearchReview[]) => {
  if (!searchReviewList) return []

  const menuItems: MenuItem[] = searchReviewList.map((review) => ({
    key: review.id,
    label: (
      <Link to={`${routePaths.SEARCH_REVIEW_DETAILS}/${review.id}`}>
        {truncateWithTooltip(review.title || '')}
      </Link>
    ),
  }))
  return menuItems
}

const getSearchReviewSearchMenuItems = (
  searchReviewId?: string,
  searchQueryList?: SearchQuery[]
) => {
  const menuItems: MenuItem[] | undefined = searchQueryList?.map((query) => ({
    key: query.searchEvent,
    label: (
      <Link
        to={`${routePaths.SEARCH_REVIEW_SEARCH}/${searchReviewId}/${query.searchEvent}`}
      >
        {truncateWithTooltip(query.searchText)}
      </Link>
    ),
  }))
  return menuItems || []
}

const getUserMenuItems = (userList: User[]) => {
  if (!userList) return []

  const menuItems: MenuItem[] = userList.map((user) => ({
    key: user.id,
    label: (
      <Link to={`${routePaths.USER_DETAILS}/${user.id}`}>
        {truncateWithTooltip(user.displayName)}
      </Link>
    ),
  }))
  return menuItems
}

const getBreadcrumbItem = (
  label: string,
  icon?: React.ReactNode,
  path = '',
  menuItems?: MenuItem[]
): BreadcrumbItemType => {
  const truncatedLabel = truncateWithTooltip(label)
  const content = !!icon ? (
    <>
      {icon} {truncatedLabel}
    </>
  ) : (
    truncatedLabel
  )
  
  return {
    title: <>{path ? <Link to={path}>{content}</Link> : content}</>,
    menu: menuItems
      ? {
          items: (menuItems as MenuItem[]) || [],
          style: {
            maxHeight: 300,
            overflow: 'auto',
          },
        }
      : undefined,
  }
}

const getDataSourceMenuItems = (dataSourcesList: DataSource[]) => {
  if (!dataSourcesList) return []

  const menuItems: MenuItem[] = dataSourcesList.map((dataSource) => {
    const title = dataSourceService.getDataSourceTitle(dataSource)
    return {
      key: dataSource.id,
      label: (
        <Link to={`${routePaths.DATA_SOURCE_DETAILS}/${dataSource.id}`}>
          {truncateWithTooltip(title)}
        </Link>
      ),
    }
  })
  return menuItems
}

const getDataStoreMenuItems = (dataStoresList: DataStore[]) => {
  if (!dataStoresList) return []

  const menuItems: MenuItem[] = dataStoresList.map((dataStore) => ({
    key: dataStore.id,
    label: (
      <Link to={`${routePaths.DATA_STORE_DETAILS}/${dataStore.id}`}>
        {truncateWithTooltip(dataStore.title)}
      </Link>
    ),
  }))
  return menuItems
}

const getOfrDocumentTemplateMenuItems = (
  ofrDocumentTemplateList: OfrDocumentTemplate[]
) => {
  if (!ofrDocumentTemplateList) return []

  const menuItems: MenuItem[] = ofrDocumentTemplateList.map(
    (documentTemplate) => ({
      key: documentTemplate.id,
      label: (
        <Link
          to={`${routePaths.OFR_DOCUMENT_TEMPLATE_DETAILS}/${documentTemplate.id}`}
        >
          {truncateWithTooltip(documentTemplate.title)}
        </Link>
      ),
    })
  )
  return menuItems
}

const breadcrumbService = {
  getWorkspaceMenuItems,
  getSearchReviewMenuItems,
  getSearchReviewSearchMenuItems,
  getUserMenuItems,
  getDataSourceMenuItems,
  getDataStoreMenuItems,
  getBreadcrumbItem,
  getOfrDocumentTemplateMenuItems,
}

export default breadcrumbService
