import { t } from 'i18next'
import SimpleSwitch from './SimpleSwitch'
import SimpleSwitchContainer from './display/SimpleSwitchContainer'

interface InclusionSwitchProps {
  id?: string
  checked?: boolean
  loading?: boolean
  disabled?: boolean
  onClick?: (value: boolean) => void
}

const InclusionSwitch = ({ id, checked, ...props }: InclusionSwitchProps) => {
  return (
    <SimpleSwitchContainer>
      <SimpleSwitch
        data-testid={`simple-switch-${id}`}
        checked={checked}
        {...props}
      />
      <div data-testid={`simple-switch-text-${id}`}>
        {checked ? t('Included') : t('Excluded')}
      </div>
    </SimpleSwitchContainer>
  )
}

export default InclusionSwitch
