import { AxiosError } from 'axios'
import { t } from 'i18next'
import moment from 'moment'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { searchMethods } from '../constants/constants'
import { routePaths } from '../pages/RootPage'
import { searchService } from '../services'
import notificationService from '../services/notificationService'
import {
  SearchQueryParams,
  setFillSearchFormData,
  setSearchLoading,
  setSearchQueryParams,
  setSearchResult,
} from '../store/reducers/searchResultInfoReducer'
import { BackEndSortOrder } from '../types/generalTypes'

interface HandleSearchProps {
  sourceId: string
  startDate: string
  endDate: string
  period: string
  searchText: any
  page?: number
  sortField?: string
  sortOrder?: BackEndSortOrder
}

const useSearchDocuments = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [error, setError] = useState<AxiosError | Error>()

  const searchLoading = useSelector(
    (state: any) => state.searchResultInfo.loading
  )

  const areCustomDateValid = useCallback(
    (startDate?: string, endDate?: string) => {
      // Empty string are considered valid data
      if (startDate === '' && endDate === '') {
        return true
      }

      const mStartDate = moment(startDate)
      const mEndDate = moment(endDate)
      return (
        mStartDate.isValid() &&
        mEndDate.isValid() &&
        mStartDate.isBefore(mEndDate)
      )
    },
    []
  )

  const performSearchWithStoreParams = useCallback(async (page: number) => {},
  [])

  const performSearch = useCallback(
    async ({
      searchText,
      sourceId,
      startDate,
      endDate,
      period,
      page,
      sortField,
      sortOrder,
    }: HandleSearchProps) => {
      if (areCustomDateValid(startDate, endDate)) {
        dispatch(setSearchLoading(true))
        await searchService
          .search({
            searchQuery: searchText,
            searchMethod: searchMethods.KEYWORD_SEARCH_METHOD,
            source: sourceId,
            startDate: startDate,
            endDate: endDate,
            page,
            sortField,
            sortOrder,
            navigate,
          })
          .then(
            (response: any) => {
              const searchQueryInfo: SearchQueryParams = {
                query: searchText,
                sourceId,
                period,
              }
              dispatch(setSearchQueryParams(searchQueryInfo))
              dispatch(setSearchResult(response))
              dispatch(setSearchLoading(false))
              navigate(routePaths.SEARCH)
            },
            (error: AxiosError | Error) => {
              setError(error)
              console.error('Search error', error)
              dispatch(setSearchLoading(false))
            }
          )
      } else {
        notificationService.notificationError(
          t('errorMessage.selectCustomPeriodDateError')
        )
      }
    },
    [areCustomDateValid, dispatch, navigate]
  )
  
  interface FindSimilarDocumentProps {
    searchText?: string
  }

  const findSimilarDocuments = useCallback(
    ({ searchText }: FindSimilarDocumentProps) => {
      dispatch(setFillSearchFormData({ searchTextValue: searchText }))
      navigate(routePaths.SEARCH)
    },
    [dispatch, navigate]
  )
  return {
    error,
    searchLoading,
    performSearch,
    performSearchWithStoreParams,
    areCustomDateValid,
    findSimilarDocuments,
  }
}

export default useSearchDocuments
