import {
  algorithmRunStatuses,
  riskReliefDeviationRisks,
  riskReliefDeviationSeverities,
} from '../../constants/constants'
import {
  AlgorithmRun,
  CAPAGeneration,
  NodeResults,
  RiskReliefDeviationClassification,
  RiskReliefDeviationClassificationGlobal,
  RiskReliefDeviationClassificationResult,
  RiskReliefDeviationClassificationRunResult,
} from '../../store/reducers/workspaceReducer'

const deviationClassificationGlobal: RiskReliefDeviationClassificationGlobal = {
  detectability: riskReliefDeviationSeverities.LOW,
  occurrence: riskReliefDeviationSeverities.MEDIUM,
  severity: riskReliefDeviationSeverities.HIGH,
  risk: riskReliefDeviationRisks.INCIDENT,
}

const deviationClassification: RiskReliefDeviationClassification = {
  id: '01',
  detectability: riskReliefDeviationSeverities.LOW,
  occurrence: riskReliefDeviationSeverities.MEDIUM,
  severity: riskReliefDeviationSeverities.HIGH,
  risk: riskReliefDeviationRisks.INCIDENT,
  identifiedRisk: 'Mocked data',
}

const deviationClassifications: RiskReliefDeviationClassification[] = [
  {
    ...deviationClassification,
  },
  {
    ...deviationClassification,
    id: '02',
    risk: riskReliefDeviationRisks.MINOR,
  },
  {
    ...deviationClassification,
    id: '03',
    risk: riskReliefDeviationRisks.MAJOR,
  },
  {
    ...deviationClassification,
    id: '04',
    risk: riskReliefDeviationRisks.CRITICAL,
  },
]

const rootCauses = {
  MockedCause: { count: 6, score: 1.5 },
  MockedCause2: { count: 5, score: 1.5 },
}

const capaItems: CAPAGeneration[] = [
  {
    id: '1',
    rootCause: 'Equipment',
    correctiveAction:
      'Technician performed routine maintenance on Refrigerator ABC, replacing faulty thermistor and ensuring proper calibration of temperature control system. Technician also re-checked and re-calibrated temperature sensors to ensure accuracy. Supervisor approved and implemented corrective action to prevent future occurrences.',
    preventiveAction:
      'Supervisor will schedule regular maintenance checks on Refrigerator ABC to ensure equipment is functioning properly and temperature control system is accurate. Additionally, Supervisor will review and update SOP 123 to include temperature control system calibration and maintenance procedures.',
  },
  {
    id: '2',
    rootCause: 'Design',
    correctiveAction:
      'Supervisor reviewed and updated SOP 123 to include temperature control system calibration and maintenance procedures. Supervisor also revised procedure to include proper sample handling and storage protocols, ensuring accurate results and regulatory compliance. Technician was re-trained on updated procedures.',
    preventiveAction:
      'Supervisor will review and update SOP 123 to include temperature control system calibration and maintenance procedures. Additionally, Supervisor will schedule regular training sessions for technicians to ensure they are familiar with updated procedures and protocols.',
  },
]

const resultRun: RiskReliefDeviationClassificationRunResult = {
  deviationClassification: deviationClassifications,
  deviationClassificationGlobal: deviationClassificationGlobal,
}

const deviationClassificationResult: RiskReliefDeviationClassificationResult = {
  deviationClassification: deviationClassifications,
  deviationClassificationGlobal: deviationClassificationGlobal,
}

const nodeResults: NodeResults = {
  DEVIATION_CLASSIFICATION: deviationClassificationResult,
  ROOT_CAUSE_DISCOVERY: rootCauses,
  CAPA_GENERATION: {
    capaGenerations: capaItems,
  },
}

const algorithmRunsArray: AlgorithmRun[] = [
  {
    id: '1',
    workspace: undefined,
    dataSource: undefined,
    runStatus: algorithmRunStatuses.ONGOING_AI,
    outputPath: 'string',
    runResult: resultRun,
    nodeResults: nodeResults,
    actionBy: undefined,
    actionDate: '2023-10-05T15:42:41.893Z',
    completedDate: '2023-10-05T15:42:41.893Z',
  },
  {
    id: '2',
    workspace: undefined,
    dataSource: undefined,
    runStatus: algorithmRunStatuses.COMPLETE,
    outputPath: 'string',
    runResult: resultRun,
    nodeResults: nodeResults,
    actionBy: undefined,
    actionDate: '2023-10-05T15:42:41.893Z',
    completedDate: '2023-10-05T15:42:41.893Z',
  },
  {
    id: '3',
    workspace: undefined,
    dataSource: undefined,
    runStatus: algorithmRunStatuses.FAILED,
    outputPath: 'string',
    runResult: undefined,
    actionBy: undefined,
    actionDate: '2023-10-05T15:42:41.893Z',
    completedDate: '2023-10-05T15:42:41.893Z',
  },
  {
    id: '4',
    workspace: undefined,
    dataSource: undefined,
    runStatus: algorithmRunStatuses.READY,
    outputPath: 'string',
    runResult: undefined,
    actionBy: undefined,
    actionDate: '2023-10-05T15:42:41.893Z',
    completedDate: '2023-10-05T15:42:41.893Z',
  },
  {
    id: '5',
    workspace: undefined,
    dataSource: undefined,
    runStatus: algorithmRunStatuses.CANCELLED,
    outputPath: 'string',
    runResult: undefined,
    actionBy: undefined,
    actionDate: '2023-10-05T15:42:41.893Z',
    completedDate: '2023-10-05T15:42:41.893Z',
  },
]

export const workspaceAlgorithmRunRiskReliefMock = algorithmRunsArray[1]
