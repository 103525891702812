import { t } from 'i18next'
import { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAlgorithmRunDetails, useWorkspaceDetails } from '../../../../hooks'
import { routePaths } from '../../../RootPage'
import RiskReliefCell from '../../components/RiskReliefCell'
import RiskReliefSmartFunctionHeader from '../../components/RiskReliefSmartFunctionHeader'

interface RootCauseDisplayFormat {
  text: string
  count: number
}

const RiskReliefRootCauseDiscoverySmartFunctionDetailsPageHeader = () => {
  const navigate = useNavigate()
  const { workspaceId, algorithmRunId, deviationId } = useParams()
  const { selectedWorkspace } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })
  const { selectedAlgorithmRun } = useAlgorithmRunDetails({
    algorithmRunId,
    workspaceId,
  })

  const backButtonUrl = useMemo(
    () =>
      `${routePaths.WORKSPACE_DETAILS}/${selectedWorkspace?.id}/deviations/smartFunction/${deviationId}`,
    [selectedWorkspace?.id, deviationId]
  )

  const rootCauseDiscovery = useMemo(
    () =>
      selectedAlgorithmRun?.nodeResults
        ? selectedAlgorithmRun?.nodeResults?.ROOT_CAUSE_DISCOVERY
        : undefined,
    [selectedAlgorithmRun]
  )

  const formattedData = useMemo(() => {
    let rootCauses: RootCauseDisplayFormat[] = []
    for (const [key, value] of Object.entries(rootCauseDiscovery ?? {})) {
      rootCauses.push({
        text: key,
        count: value.count,
      } as RootCauseDisplayFormat)
    }

    return rootCauses.sort((a, b) => b.count - a.count).slice(0, 5)
  }, [rootCauseDiscovery])

  const onRunChange = useCallback(
    (algorithmRunId?: string) => {
      const url = `${routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_ROOT_CAUSE_DISCOVERY}/${workspaceId}/${algorithmRunId}/${deviationId}`
      navigate(url)
    },
    [navigate, workspaceId, deviationId]
  )

  return (
    <RiskReliefSmartFunctionHeader
      title={t(
        'workspaceDetailsPage.riskReliefSmartFunction.rootCauseDiscovery'
      )}
      backButtonUrl={backButtonUrl}
      onRunChange={onRunChange}
    >
      {formattedData.map((rootCause, index) => (
        <RiskReliefCell
          key={index}
          title={t(
            'smartFunctionDetailsPage.riskReliefRootCause.header.cellTitle',
            { count: rootCause.count, position: index + 1 }
          )}
          text={rootCause.text}
        />
      ))}
    </RiskReliefSmartFunctionHeader>
  )
}

export default RiskReliefRootCauseDiscoverySmartFunctionDetailsPageHeader
