import { t } from 'i18next'
import { ReactNode, useMemo } from 'react'
import Moment from 'react-moment'
import { useLocation, useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import BackButton from '../../../components/BackButton'
import Badge from '../../../components/display/Badge'
import BoldText from '../../../components/display/BoldText'
import { SmartFunctionRunStatusIcon } from '../../../components'
import {
  NO_DATA_PLACEHOLDER,
  dateFormat,
  smartFunctionBadgeStatusColorKeys,
} from '../../../constants/constants'
import { useAlgorithmRunDetails, useWorkspaceDetails } from '../../../hooks'
import { NodeStatuses } from '../../../store/reducers/workspaceReducer'
import { AlgorithmRunStatus } from '../../../types/generalTypes'
import { routePaths } from '../../RootPage'
import SmartFunctionActionDropdown from './SmartFunctionActionDropdown/SmartFunctionActionDropdown'
import SmartFunctionSelectRun from './SmartFunctionSelectRun'

export const SmartFunctionSubHeader = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 12px;
  gap: 16px;
`

export const SmartFunctionMainSubHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const SmartFunctionSubHeaderLine = styled.div`
  display: flex;
  flex: 1;
  padding: 0 8px;
  gap: 16px;
  color: ${(props) => props.theme.token.colorText};
`

export const SmartFunctionSubHeaderCell = styled.div`
  flex: 50%;
  display: flex;
  align-items: center;
`

export const SmartFunctionSubHeaderCellContainer = styled.div`
  flex: 50%;
  display: flex;
`

export const SmartFunctionSubHeaderCellSection = styled.div`
  align-content: center;
  flex: auto;
  color: ${(props) => props.theme.token.colorText};
`

const SmartFunctionHeaderFirstRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 8px;
`

interface RiskReliefSmartFunctionHeaderLayoutProps {
  backButtonUrl: string
  children?: ReactNode
  onRunChange?: (value?: string) => void
  title?: string
}

const RiskReliefSmartFunctionHeader = ({
  backButtonUrl,
  children,
  onRunChange,
  title,
}: RiskReliefSmartFunctionHeaderLayoutProps) => {
  const { workspaceId, algorithmRunId } = useParams()
  const location = useLocation()
  const { selectedAlgorithmRun } = useAlgorithmRunDetails({
    algorithmRunId,
    workspaceId,
    preventFetch: true,
  })
  const { selectedWorkspace } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  const currentNodeStatus = useMemo(() => {
    if (!selectedAlgorithmRun?.nodeStatuses) return undefined

    const routeToNodeMap = {
      [routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_CAPA_GENERATION]:
        'CAPA_GENERATION',
      [routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_ROOT_CAUSE_DISCOVERY]:
        'ROOT_CAUSE_DISCOVERY',
      [routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_DEVIATION_CLASSIFICATION]:
        'DEVIATION_CLASSIFICATION',
    }

    const matchedRoute = Object.keys(routeToNodeMap).find((path) =>
      location.pathname.includes(path)
    )

    return matchedRoute
      ? selectedAlgorithmRun.nodeStatuses[
          routeToNodeMap[matchedRoute] as keyof NodeStatuses
        ]
      : undefined
  }, [selectedAlgorithmRun?.nodeStatuses, location.pathname])

  return (
    <SmartFunctionSubHeader>
      <BackButton
        url={backButtonUrl}
        tooltipMessage={t('smartFunctionDetailsPage.backButtonTooltip')}
        type="default"
      />
      <SmartFunctionMainSubHeader>
        <SmartFunctionHeaderFirstRow>
          <SmartFunctionSubHeaderLine>
            <SmartFunctionSubHeaderCell>
              <BoldText>
                {title ||
                  selectedWorkspace?.algorithmName ||
                  NO_DATA_PLACEHOLDER}
              </BoldText>
            </SmartFunctionSubHeaderCell>
            <SmartFunctionSubHeaderCellContainer>
              <SmartFunctionSubHeaderCellSection>
                {selectedAlgorithmRun?.actionDate ? (
                  <Moment
                    local
                    date={selectedAlgorithmRun?.actionDate}
                    format={dateFormat.PRIMARY}
                  />
                ) : (
                  NO_DATA_PLACEHOLDER
                )}
              </SmartFunctionSubHeaderCellSection>
              <SmartFunctionSubHeaderCellSection>
                <SmartFunctionSelectRun onChange={onRunChange} />
              </SmartFunctionSubHeaderCellSection>
              <SmartFunctionSubHeaderCellSection>
                <Badge
                  color={
                    smartFunctionBadgeStatusColorKeys[
                      currentNodeStatus as AlgorithmRunStatus
                    ]
                  }
                  icon={
                    <SmartFunctionRunStatusIcon
                      status={currentNodeStatus as AlgorithmRunStatus}
                    />
                  }
                >
                  {currentNodeStatus
                    ? t(`workspaceDetailsPage.status.${currentNodeStatus}`)
                    : NO_DATA_PLACEHOLDER}
                </Badge>
              </SmartFunctionSubHeaderCellSection>
            </SmartFunctionSubHeaderCellContainer>
          </SmartFunctionSubHeaderLine>
          <SmartFunctionActionDropdown
            key={selectedAlgorithmRun?.id}
            algorithmRun={selectedAlgorithmRun}
          />
        </SmartFunctionHeaderFirstRow>
        <SmartFunctionSubHeaderLine>{children}</SmartFunctionSubHeaderLine>
      </SmartFunctionMainSubHeader>
    </SmartFunctionSubHeader>
  )
}

export default RiskReliefSmartFunctionHeader
