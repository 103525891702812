import { useState, useCallback } from 'react'
import { t } from 'i18next'
import {
  RiskReliefDeviationConfigValues,
  RiskReliefRootCauseDetails,
} from '../store/reducers/workspaceReducer'
import notificationService from '../services/notificationService'
import useWorkspaceDetails from './useWorkspaceDetails'

export interface RiskReliefRootCauseDetailsExtended
  extends RiskReliefRootCauseDetails {
  included: boolean
}

export interface RootCauseMainRow {
  tag: string
  key: string
  count: number
  total: number
  toggle: boolean
  details: RiskReliefRootCauseDetailsExtended[]
}

export interface UseRootCauseExclusionsProps {
  workspaceId?: string
  formattedData: RootCauseMainRow[]
}

export const useRootCauseExclusions = ({
  workspaceId,
  formattedData,
}: UseRootCauseExclusionsProps) => {
  const [loadingIds, setLoadingIds] = useState<string[]>([])
  const { selectedWorkspace, updateWorkspaceDetails } = useWorkspaceDetails({
    workspaceId,
  })

  const appendLoadingId = useCallback((recordId: string) => {
    setLoadingIds((prev) => [...prev, recordId])
  }, [])

  const removeLoadingId = useCallback((recordId: string) => {
    setLoadingIds((prev) => prev.filter((id) => id !== recordId))
  }, [])

  const handleMasterOnSwitchClick = useCallback(
    async (recordDetails: any[], rootCauseTag: string) => {
      const currentConfig =
        selectedWorkspace?.config?.riskReliefConfig ||
        ({} as RiskReliefDeviationConfigValues)
      const rootCauseExclusions = currentConfig?.rootCauseExclusions || {}
      const documentIds = recordDetails.map((detail) => detail.documentId)

      const currentExcludedIds = rootCauseExclusions[rootCauseTag] || []
      const hasUnexcludedDocuments = documentIds.some(
        (id) => !currentExcludedIds.includes(id)
      )

      recordDetails.forEach((detail) => appendLoadingId(detail.documentId))

      try {
        const updatedExclusions = { ...rootCauseExclusions }
        if (hasUnexcludedDocuments) {
          updatedExclusions[rootCauseTag] = documentIds
        } else {
          delete updatedExclusions[rootCauseTag]
        }

        await updateWorkspaceDetails({
          ...selectedWorkspace?.config,
          riskReliefConfig: {
            ...currentConfig,
            rootCauseExclusions: updatedExclusions,
          },
        })
        notificationService.notificationSuccess(
          t(
            `smartFunctionDetailsPage.riskReliefRootCause.${
              hasUnexcludedDocuments ? 'excludeAllSuccess' : 'includeAllSuccess'
            }`,
            {
              tag: rootCauseTag,
            }
          )
        )
      } catch (error) {
        console.error('Error updating exclusions:', error)
        notificationService.notificationError(
          t('smartFunctionDetailsPage.riskReliefRootCause.updateError')
        )
      } finally {
        recordDetails.forEach((detail) => removeLoadingId(detail.documentId))
      }
    },
    [
      selectedWorkspace,
      updateWorkspaceDetails,
      appendLoadingId,
      removeLoadingId,
    ]
  )

  const handleOnSwitchClick = useCallback(
    async (documentId: string, rootCauseTag: string) => {
      const currentConfig =
        selectedWorkspace?.config?.riskReliefConfig ||
        ({} as RiskReliefDeviationConfigValues)
      const rootCauseExclusions = currentConfig.rootCauseExclusions || {}
      const currentExcludedIds = rootCauseExclusions[rootCauseTag] || []

      const allDocumentsInGroup =
        formattedData
          .find((row: RootCauseMainRow) => row.tag === rootCauseTag)
          ?.details.map(
            (detail: RiskReliefRootCauseDetailsExtended) => detail.documentId
          ) || []

      allDocumentsInGroup.forEach((id: string) => appendLoadingId(id))

      try {
        const updatedExclusions = { ...rootCauseExclusions }
        const isCurrentlyExcluded = currentExcludedIds.includes(documentId)

        if (isCurrentlyExcluded) {
          const newExcludedIds = currentExcludedIds.filter(
            (id: string) => id !== documentId
          )
          if (newExcludedIds.length === 0) {
            delete updatedExclusions[rootCauseTag]
          } else {
            updatedExclusions[rootCauseTag] = newExcludedIds
          }
        } else {
          updatedExclusions[rootCauseTag] = [...currentExcludedIds, documentId]
        }

        await updateWorkspaceDetails({
          ...selectedWorkspace?.config,
          riskReliefConfig: {
            ...currentConfig,
            rootCauseExclusions: updatedExclusions,
          },
        })

        notificationService.notificationSuccess(
          t(
            `smartFunctionDetailsPage.riskReliefRootCause.${
              isCurrentlyExcluded ? 'includeSuccess' : 'excludeSuccess'
            }`,
            {
              documentId,
              tag: rootCauseTag,
            }
          )
        )
      } catch (error) {
        console.error('Error updating exclusion:', error)
        notificationService.notificationError(
          t('smartFunctionDetailsPage.riskReliefRootCause.updateError')
        )
      } finally {
        allDocumentsInGroup.forEach((id: string) => removeLoadingId(id))
      }
    },
    [
      selectedWorkspace,
      updateWorkspaceDetails,
      formattedData,
      appendLoadingId,
      removeLoadingId,
    ]
  )

  const isDocumentExcluded = useCallback(
    (documentId: string, rootCauseTag: string) => {
      const exclusions =
        selectedWorkspace?.config?.riskReliefConfig?.rootCauseExclusions || {}
      return (exclusions[rootCauseTag] || []).includes(documentId)
    },
    [selectedWorkspace]
  )

  return {
    loadingIds,
    handleMasterOnSwitchClick,
    handleOnSwitchClick,
    isDocumentExcluded,
  }
}
