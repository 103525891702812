import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  permissionActions,
  permissionNames,
  privacyTypes,
  urlList,
} from '../constants/constants'
import { apiService, dataSourceService } from '../services'
import roleService from '../services/roleService'
import { RootState } from '../store'
import {
  DataSource,
  setDataSourceDetailsCsv,
  setDataSourceList,
  setDataSourceListCsv,
  setDataSourceLoading,
} from '../store/reducers/dataSourceReducer'
import { SearchSource } from '../store/reducers/searchReviewReducer'
import useDownloadFile from './useDownloadFile'

interface UseDataSourcesProps {
  preventFetch?: boolean
}

const useDataSources = ({ preventFetch = false }: UseDataSourcesProps) => {
  const dispatch = useDispatch()
  const loading = useSelector((state: RootState) => state.dataSource.loading)
  const currentUser = useSelector((state: RootState) => state.user.currentUser)
  const downloadCsv = useDownloadFile()
  const selectedDataSource: DataSource | undefined = useSelector(
    (state: RootState) => state.dataSource.selected
  )
  const rawDataSourceList: DataSource[] = useSelector(
    (state: RootState) => state.dataSource.dataSourceList
  )
  const dataSourceList: DataSource[] = useMemo(
    () =>
      rawDataSourceList
        .filter((dataSource: DataSource) =>
          currentUser &&
          roleService.hasPermission(
            currentUser,
            permissionNames.DATA_SOURCES,
            permissionActions.ALL
          )
            ? true
            : dataSource.visibility !== privacyTypes.PRIVATE
        )
        .map((dataSource: DataSource) => {
          return {
            ...dataSource,
            key: dataSource.id === undefined ? dataSource.id : dataSource.id,
          }
        }),
    [rawDataSourceList, currentUser]
  )

  const internalDataSourceList = useMemo(
    () => dataSourceList.filter((dataSource) => dataSource.isInternal),
    [dataSourceList]
  )

  const publicDataSourceList = useMemo(
    () => dataSourceList.filter((dataSource) => !dataSource.isInternal),
    [dataSourceList]
  )

  const searchableDataSourceList = useMemo(
    () => dataSourceList.filter((dataSource) => dataSource.searchable),
    [dataSourceList]
  )

  const fetchDataSourceList = useCallback(
    (refreshForceCall = false) => {
      if (!loading && (!preventFetch || refreshForceCall)) {
        dispatch(setDataSourceLoading(true))
        apiService
          .fetchItems(urlList.DATA_SOURCES)
          .then((response: any) => {
            dispatch(setDataSourceLoading(false))
            dispatch(setDataSourceList(response.data))
          })
          .catch((error) => {
            console.error('axios fetch error', error)
            dispatch(setDataSourceLoading(false))
          })
      }
    },
    [dispatch, loading, preventFetch]
  )

  const refreshDataSourceList = useCallback(async () => {
    fetchDataSourceList(true)
  }, [fetchDataSourceList])

  useEffect(() => {
    fetchDataSourceList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const exportDataSourceListCsv = useCallback(() => {
    if (dataSourceList) {
      dataSourceService
        .exportDataSourceList()
        .then((response) => {
          const fileName = 'data_sources.csv'
          downloadCsv(response, fileName)
          dispatch(setDataSourceListCsv(response))
        })
        .catch((error: any) => {
          console.error('Error fetching data source list CSV data', error)
        })
    }
  }, [dataSourceList, dispatch, downloadCsv])

  const exportDataSourceDetailsCsv = useCallback(
    (dataSourceId: string) => {
      if (
        !selectedDataSource ||
        dataSourceId === undefined ||
        dataSourceId === ''
      )
        return

      dataSourceService
        .exportDataSourceDetails({ dataSourceId })
        .then((response) => {
          const fileName = `${selectedDataSource?.title}_${selectedDataSource?.createdDate}.csv`
          downloadCsv(response, fileName)
          dispatch(setDataSourceDetailsCsv(response))
        })
        .catch((error: any) => {
          console.error('Error fetching data source details CSV data', error)
        })
    },
    [downloadCsv, selectedDataSource, dispatch]
  )

  const getDataSourceFiles = useCallback(
    ({ dataSourceId, format }: { dataSourceId: string; format?: string }) => {
      if (!dataSourceId) return

      return dataSourceService
        .getDataSourceFiles({
          dataSourceId,
          format,
        })
        .then((response) => {
          return response
        })
        .catch((error) => {
          console.error('axios get error', error)
          throw error
        })
    },
    []
  )

  const getDataSourceTypeText = useCallback(
    (dataSource: DataSource | string | SearchSource | undefined) => {
      if (typeof dataSource === 'string') {
        return dataSource
      } else if (dataSource) {
        if (Object.keys(dataSource).length === 0) {
          return ''
        }
        return `${dataSource.typeCode} : ${dataSource.title}`
      }
      return ''
    },
    []
  )

  return {
    dataSourceList,
    exportDataSourceDetailsCsv,
    exportDataSourceListCsv,
    internalDataSourceList,
    loading,
    publicDataSourceList,
    searchableDataSourceList,
    refreshDataSourceList,
    selectedDataSource,
    getDataSourceFiles,
    getDataSourceTypeText,
  }
}

export default useDataSources
